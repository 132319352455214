<template>
    <KCourse loader-class="MBcont" course-id="2" title="Анатомия тела" :items="items">
        <div class="MBtextcont">
            <p>Как я уже говорила ранее, детали можно вскрывать уже на следующие сутки, но все зависит от влажности и температуры помещения. Иногда в дождливый сезон заготовки сохли пару суток и приходилось их осторожно вскрывать и досушивать в духовке, а иногда было так жарко и сухо, что уже через 12 часов они были готовы к вскрытию.</p>
            <p class='nomargin'><b>Как определить готовы ли детали?</b></p>
            <p>Верхний слой должен казаться совершенно сухим и твердым. Если на него нажать он может слегка промяться или даже не поддаться вашим воздействиям, а вот нож плавно и легко разрежет такой пластик. После снятия с пластилина они отлично держат форму.</p>
            <p class='nomargin'><b>На этом занятии нам понадобятся:</b></p>
            <ol>
                <li>Трафареты</li>
                <li>Пластик La Doll</li>
                <li>Нож или стек лопаткой</li>
                <li>Обтянутые пластиком заготовки</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Карандаш</li>
                <li>Макетный нож</li>
                <li>Вода</li>
            </ol>
        </div>

        <VideoView video-id="c4837e62bdf24348b5a968b10194b58a"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Задание:</b></p>
            <p>Достать пластилиновые основы из пластика и соединить детали обратно.</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Готовим трафареты", url:"/mycourses/body/1"},
                { title: "Заготовки из пластилина", url:"/mycourses/body/2"},
                { title: "Подготовка к обтяжке", url:"/mycourses/body/3"},
                { title: "Обтяжка пластиком", url:"/mycourses/body/4"},
                { title: "Достаем пластилин", url:"/mycourses/body/5"},
                { title: "Основа кисти", url:"/mycourses/body/6"},
                { title: "Основа стопы", url:"/mycourses/body/7"},
                { title: "Анатомия руки", url:"/mycourses/body/8"},
                { title: "Анатомия кисти", url:"/mycourses/body/9"},
                { title: "Анатомия ноги", url:"/mycourses/body/10"},
                { title: "Анатомия стоп", url:"/mycourses/body/11"},
                { title: "Анатомия тела", url:"/mycourses/body/12"},
            ],
        }
        }
    }
</script>